import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import posed from 'react-pose';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';

const OptionsBox = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' },
});

function DropDownSelect(props) {
  const [state, dispatch] = useContext(Context);
  const { keyset, title, options, allValues } = props;
  const [alloptions, setAlloptions] = useState(true);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState([]);

  const selectOption = val => {
    val == 0 ? setAlloptions(true) : setAlloptions(false);

    var newValues = [];

    if (values.indexOf(val) == -1 && val != 0) {
      newValues = values.concat(val);
    } else if (values.indexOf(val) > -1 && val !== 0) {
      newValues = values.filter(item => item !== val);
      if (newValues.length == 0) {
        setAlloptions(true);
      }
    } else {
      newValues = [];
    }

    setValues(newValues);
    dispatch({ type: keyset, payload: newValues });
  };

  const toggleFilterDropdown = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (state.department_param) {
      const newValues = [state.department_param];
      setValues(newValues);
      if (keyset === 'FILTER_DEPARTMENT') {
        setAlloptions(false);
        setOpen(true);
      }
      dispatch({ type: 'SET_DEPTARTMENT_PARAM', payload: '' });
    }
  }, []);

  useEffect(() => {
    if (state.reset_filters) {
      setValues([]);
      setAlloptions(true);
      dispatch({ type: 'SET_FILTER_RESET', payload: false });
    }
  }, [state.reset_filters]);

  return (
    <Box width="100%">
      <Box
        component="button"
        onClick={() => toggleFilterDropdown()}
        sx={{
          width: '100%',
          border: 0,
          background: 'none',
          padding: 0,
          cursor: 'pointer',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ borderBottom: '1px solid', borderBottomColor: 'text.xlgrey' }}
        >
          <Typography component="p" variant="subtitle2">
            {title}
          </Typography>
          {!open ? (
            <KeyboardArrowDownIcon
              sx={{ color: 'text.dblue', fontSize: '2rem' }}
            />
          ) : (
            <KeyboardArrowUpIcon
              sx={{ color: 'text.dblue', fontSize: '2rem' }}
            />
          )}
        </Box>
      </Box>
      <OptionsBox
        pose={open ? 'open' : 'closed'}
        style={{ overflow: 'hidden' }}
      >
        {options && (
          <Box pt={5} display="flex" flexDirection="column">
            <Box
              component="button"
              sx={{
                background: 'none',
                border: 0,
                padding: 0,
                cursor: 'pointer',
              }}
              onClick={() => selectOption(0)}
            >
              <Box display="flex" alignItems="center" mb={2}>
                <Box
                  mr={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '25px',
                    border: '1px solid',
                    borderColor: `${
                      alloptions ? 'secondary.main' : 'text.xlgrey'
                    }`,
                    backgroundColor: `${alloptions ? 'secondary.main' : ''}`,
                  }}
                >
                  {alloptions && (
                    <CheckIcon
                      sx={{ color: 'text.white', fontSize: '1.2rem' }}
                    />
                  )}
                </Box>
                <Typography
                  component="p"
                  variant="body1"
                  sx={{ marginBottom: '0' }}
                >
                  {allValues}
                </Typography>
              </Box>
            </Box>
            {options.map((item, key) => {
              const isSelected = values.indexOf(item.node.gh_Id) > -1;
              return (
                <Box
                  component="button"
                  sx={{
                    background: 'none',
                    border: 0,
                    padding: 0,
                    cursor: 'pointer',
                  }}
                  onClick={() => selectOption(item.node.gh_Id)}
                  key={key}
                >
                  <Box display="flex" alignItems="center" mb={2}>
                    <Box
                      mr={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '25px',
                        border: '1px solid',
                        borderColor: `${
                          isSelected ? 'secondary.main' : 'text.xlgrey'
                        }`,
                        backgroundColor: `${
                          isSelected ? 'secondary.main' : ''
                        }`,
                      }}
                    >
                      {isSelected && (
                        <CheckIcon
                          sx={{ color: 'text.white', fontSize: '1.2rem' }}
                        />
                      )}
                    </Box>
                    <Typography
                      component="p"
                      variant="body1"
                      align="left"
                      sx={{ marginBottom: '0' }}
                    >
                      {item.node.name}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </OptionsBox>
    </Box>
  );
}

DropDownSelect.propTypes = {
  keyset: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  allValues: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default DropDownSelect;
