import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import { Index } from 'elasticlunr';
import { Link } from 'gatsby';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

const resultsWrapper = {
  boxShadow: '0px 4px 8px 0px rgb(207, 213, 219)',
  backgroundColor: '#fff',
  display: 'none',
};

// Search component
function RoleSearchField(props) {
  const { searchIndex } = props;
  const [state, dispatch] = useContext(Context);
  const [index] = useState(Index.load(searchIndex));
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const clearSearch = () => {
    setQuery('');
    setResults([]);
  };

  const search = evt => {
    setQuery(evt.target.value);
    dispatch({ type: 'SET_ROLE_SEARCH', payload: evt.target.value });
    setResults([
      index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => index.documentStore.getDoc(ref)),
    ]);
  };

  useEffect(() => {
    if (state.reset_filters) {
      setQuery('');
      setResults([]);
      dispatch({ type: 'SET_FILTER_RESET', payload: false });
    }
  }, [state.reset_filters]);

  return (
    <React.Fragment>
      <Box position="relative">
        <Grid container columnSpacing={8}>
          <Grid item xs={12}>
            <Box component="label" htmlFor="search" mb={1}>
              <Typography component="span" variant="subtitle2">
                Role Search
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box position="relative">
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
                ml={2}
              >
                <SearchIcon sx={{ color: '#0C1C2F' }} />
              </Box>
              <Box
                component="input"
                type="text"
                id="search"
                name="jobSearch"
                placeholder="Search by role or keyword"
                value={query}
                onChange={search}
                autoComplete="off"
                pt={2.5}
                pb={2.5}
                pr={2.5}
                pl={6}
                sx={{
                  fontSize: '14px',
                  width: '100%',
                  border: '2px solid',
                  borderColor: 'text.xlgrey',
                  borderRadius: '7px',
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box position="absolute" width="100%" style={resultsWrapper}>
          {results && results[0] && results[0].length > 0 && query.length > 0 && (
            <ul>
              {results.map((item, key) => {
                return (
                  <li key={key}>
                    <Link to="">{item[key].title}</Link>
                  </li>
                );
              })}
            </ul>
          )}
          {results && results[0] && results[0].length == 0 && query.length > 3 && (
            <Box p={1}>
              <Typography variant="h5">
                We couldn&apos;t find an exact match
              </Typography>
              <Box py={[0.5]}>
                <Typography>
                  Don&apos;t worry, sometimes our roles could differ in name to
                  what you&apos;ve searched for. Try searching again
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

RoleSearchField.propTypes = {
  searchIndex: PropTypes.object,
};

export default RoleSearchField;
