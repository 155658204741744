import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function Spacer(props) {
  const { children, removeBottomPadding } = props;
  const spacer_value = process.env.DEFAULT_SPACER_VALUE;
  return (
    <Box
      pt={[3, 3, parseInt(spacer_value / 2)]}
      pb={!removeBottomPadding ? [3, 3, parseInt(spacer_value / 2)] : 0}
      className="spacer"
    >
      {children}
    </Box>
  );
}

Spacer.propTypes = {
  children: PropTypes.node.isRequired,
  removeBottomPadding: PropTypes.bool,
};

export default Spacer;
