import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { RoleSearchField, DropDownSelect } from 'components/ui';

function JobSearchUi(props) {
  const { searchIndex, offices, departments } = props;
  return (
    <Box className="spaced-grid-wrapper" mb={4}>
      <Grid container spacing={{ xs: 3, md: 8 }}>
        <Grid item xs={12} lg={4}>
          <RoleSearchField searchIndex={searchIndex} />
        </Grid>
        <Box component={Grid} item xs={12} lg={4}>
          <Box mt={[0, 0, 0, 6.35]} />
          <DropDownSelect
            keyset="FILTER_DEPARTMENT"
            title="Teams"
            allValues="All Teams"
            options={departments}
          />
        </Box>
        <Box component={Grid} item xs={12} lg={4}>
          <Box mt={[0, 0, 0, 6.35]} />
          <DropDownSelect
            keyset="FILTER_LOCATION"
            title="Location"
            allValues="All Locations"
            options={offices}
          />
        </Box>
      </Grid>
    </Box>
  );
}

JobSearchUi.propTypes = {
  searchIndex: PropTypes.object,
  offices: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
};

export default JobSearchUi;
