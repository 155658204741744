import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import Headroom from 'react-headroom';
import Link from 'gatsby-link';
import posed from 'react-pose';
import Nav from 'components/header/nav';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import Typography from '@mui/material/Typography';
import BREAKPOINTS from 'constants/breakpoints';
import BrandLogo from 'images/brand.svg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useWindowWidth } from '@react-hook/window-size/throttled';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

function Header(props) {
  const { headerOpacity } = props;
  const [mobileMenu, setMobileMenu] = useState(false);
  const [headroomDisable, setHeadroomDisable] = useState(false);
  const onlyWidth = useWindowWidth();
  const [state, dispatch] = useContext(Context);

  const toggleMenu = () => {
    dispatch({ type: 'SET_MENU', payload: !state.menu });
  };

  useEffect(() => {
    if (onlyWidth <= BREAKPOINTS.DESKTOP) {
      setMobileMenu(true);
      setHeadroomDisable(true);
    }
    if (onlyWidth >= BREAKPOINTS.DESKTOP) {
      setMobileMenu(false);
      setHeadroomDisable(false);
    }
  }, [onlyWidth]);
  return (
    <Headroom disable={headroomDisable} disableInlineStyles>
      <Box
        py={2.75}
        sx={{
          bgcolor: `rgba(11, 27, 46, ${headerOpacity})`,
          width: '100%',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <ContainerDefault>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box component={Link} display="flex" to="/">
                <BrandLogo />
              </Box>
              <Box ml={2}>
                <Typography
                  component="p"
                  variant="body1"
                  sx={{ color: 'text.white', margin: 0 }}
                >
                  Careers
                </Typography>
              </Box>
            </Box>
            {!mobileMenu ? (
              <Nav />
            ) : (
              <Box
                onClick={() => toggleMenu()}
                component="button"
                sx={{ cursor: 'pointer', background: 'none', border: 0 }}
              >
                {!state.menu ? (
                  <MenuIcon
                    sx={{ color: '#fff', fontSize: '2rem', cursor: 'pointer' }}
                  />
                ) : (
                  <CloseIcon
                    sx={{ color: '#fff', fontSize: '2rem', cursor: 'pointer' }}
                  />
                )}
              </Box>
            )}
          </Box>
        </ContainerDefault>
      </Box>
    </Headroom>
  );
}

Header.propTypes = {
  headerOpacity: PropTypes.number.isRequired,
};

export default Header;
