import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ContainerDefault } from 'components/containers';
import linkResolver from 'helpers/linkResolver';
import BrandLogo from 'images/brand.svg';
import { ContactlessOutlined } from '@mui/icons-material';

import LogoWithBorp from 'images/Kaluza-B-Corp-footer-2023-09_350x200-stacked-300x171.png';

function DocumentLink(props) {
  const { item } = props;
  return (
    <Box
      component={Link}
      sx={{
        color: 'text.white',
        textDecoration: 'none',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
        transition: 'border-bottom-color 0.3s ease-in-out',
        '&:hover': {
          borderBottomColor: 'secondary.main',
        },
      }}
      to={item.link && item.link.type == 'page' ? linkResolver(item.link) : ''}
    >
      {item.link.document.data.page_title.text}
    </Box>
  );
}

function ExternalLink(props) {
  const { item } = props;
  return (
    <Box
      component="a"
      sx={{
        color: 'text.white',
        textDecoration: 'none',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
        transition: 'border-bottom-color 0.3s ease-in-out',
        '&:hover': {
          borderBottomColor: 'secondary.main',
        },
      }}
      target="_blank"
      href={item.link.raw.url}
    >
      {item.link_text}
    </Box>
  );
}

function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          prismicGlobalSetup {
            _previewable
            data {
              footer_navigation {
                link_text
                link {
                  link_type
                  raw
                  type
                  id
                  uid
                  document {
                    ... on PrismicPage {
                      id
                      data {
                        page_title {
                          text
                        }
                      }
                    }
                  }
                }
              }
              social_links {
                title
                icon {
                  alt
                  url
                }
                link {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Box
            component="footer"
            py={10}
            sx={{ bgcolor: 'background.secondary' }}
          >
            <ContainerDefault>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <Box mb={{ xs: 5, md: 0 }}>
                  <img src={LogoWithBorp} alt="Kaluza" />
                </Box>
                {data.prismicGlobalSetup.data.social_links &&
                  data.prismicGlobalSetup.data.social_links.length > 0 && (
                    <Box>
                      <Box mb={4}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'AzoSans-Bold, sans-serif',
                            fontWeight: '700',
                            color: 'text.white',
                          }}
                        >
                          Connect with us
                        </Typography>
                      </Box>
                      {data.prismicGlobalSetup.data.social_links.map(
                        (item, key) => {
                          if (!item.title || !item.link.url) return null;
                          return (
                            <Box
                              key={key}
                              display="flex"
                              alignItems="center"
                              mb={2}
                            >
                              {item.icon && item.icon.url && (
                                <Box display="flex" alignItems="center" mr={2}>
                                  <img
                                    style={{ width: '25px' }}
                                    src={`${item.icon.url}`}
                                    alt={item.icon.alt ? item.icon.alt : ''}
                                  />
                                </Box>
                              )}
                              <Typography
                                variant="caption"
                                component="a"
                                href={item.link.url}
                                target="_blank"
                                rel="noopener"
                                sx={{
                                  color: 'text.white',
                                  textTransform: 'uppercase',
                                  textDecoration: 'none',
                                  '&:hover': {
                                    textDecoration: 'underline',
                                  },
                                }}
                              >
                                {item.title}
                              </Typography>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  )}
              </Box>
              <Box
                sx={{ borderTop: '1px solid rgba(225, 225, 225 , 0.20)' }}
                pt={5}
                mt={5}
              >
                {data.prismicGlobalSetup.data.footer_navigation && (
                  <Box
                    component="ul"
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    flexWrap="wrap"
                    p={0}
                  >
                    {data.prismicGlobalSetup.data.footer_navigation.map(
                      (item, key) => {
                        return (
                          <Box
                            component="li"
                            mb={4}
                            sx={{
                              listStyle: 'none',
                              flex: '0 0 25%',
                            }}
                            key={key}
                          >
                            {item.link.link_type === 'Document' && (
                              <DocumentLink item={item} />
                            )}
                            {item.link.link_type === 'Web' && (
                              <ExternalLink item={item} />
                            )}
                            {item.link.link_type === 'Media' && (
                              <ExternalLink item={item} />
                            )}
                          </Box>
                        );
                      }
                    )}
                  </Box>
                )}
              </Box>
            </ContainerDefault>
          </Box>
        );
      }}
    />
  );
}

export default Footer;
