import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import Box from '@mui/material/Box';

import { MobileMenu } from 'components/ui';

function Layout(props) {
  const { children, headerOpacity } = props;
  const [state, dispatch] = useContext(Context);
  return (
    <div>
      <GlobalStyle />
      <Head />
      <Header headerOpacity={headerOpacity} />
      <MobileMenu />
      <Box
        sx={{
          transition: 'transform 0.3s ease-in-out',
          transform: `translateY(${state.menu ? '50vh' : '0'})`,
        }}
      >
        {children}
        <Footer />
      </Box>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerOpacity: PropTypes.number.isRequired,
};

export default Layout;
