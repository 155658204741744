import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import linkResolver from 'helpers/linkResolver';

function Nav() {
  const staticData = useStaticQuery(graphql`
    query MainMenuQuery {
      prismicGlobalSetup {
        _previewable
        data {
          main_navigation {
            link {
              uid
              type
              id
              document {
                ... on PrismicPage {
                  data {
                    is_homepage
                    page_title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { data, isPreview } = useMergePrismicPreviewData(staticData);
  return (
    <div>
      {data.prismicGlobalSetup.data.main_navigation && (
        <Box component="ul" display="flex" p={0}>
          {data.prismicGlobalSetup.data.main_navigation.map((item, key) => {
            return (
              <Box
                component="li"
                flexDirection="column"
                alignItems="center"
                pl={4}
                sx={{
                  listStyle: 'none',
                }}
                key={key}
              >
                <Box
                  component={Link}
                  sx={{
                    color: 'text.white',
                    textDecoration: 'none',
                    borderBottomWidth: '2px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'transparent',
                    transition: 'border-bottom-color 0.3s ease-in-out',
                    '&.active': {
                      borderBottomColor: 'secondary.main',
                    },
                    '&:hover': {
                      borderBottomColor: 'secondary.main',
                    },
                  }}
                  to={
                    item.link && item.link.type == 'page'
                      ? item.link.document.data.is_homepage
                        ? '/'
                        : linkResolver(item.link)
                      : ''
                  }
                  activeClassName="active"
                >
                  {item.link.document.data.is_homepage
                    ? 'About us'
                    : item.link.document.data.page_title.text}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </div>
  );
}

Nav.propTypes = {};

export default Nav;
