import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';

function ContainerDefault(props) {
  const { children } = props;
  return <Container maxWidth="false">{children}</Container>;
}

ContainerDefault.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContainerDefault;
