import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Helmet from 'react-helmet';

import { BtnLight } from 'components/ui';

const useStyles = makeStyles(theme => ({
  text: {
    cursor: 'pointer',
    borderRadius: '5px',
    border: '1px solid',
    padding: '6px 14px',
    color: theme.palette.text.secondary,
    backgroundColor: props => props.color,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.background.primary,
    },
  },
  appBar: {
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  toolBar: {
    padding: '0',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    height: 'auto',
    backgroundColor: theme.palette.background.primary,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btn: {
    color: theme.palette.text.green,
    backgroundColor: theme.palette.background.default,
    borderRadius: '0px',
    padding: '5px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.text.green,
      color: theme.palette.background.default,
    },
  },
  margin: {
    margin: `${theme.spacing(1.5)}px ${theme.spacing(0)}px`,
    width: '100%',
    '& label': {
      color: theme.palette.background.dgrey,
      position: 'relative',
      '&.Mui-focused': {
        color: `${alpha(theme.palette.background.primary, 0.8)}`,
      },
    },
    '& .Mui-error': {
      '& input': {
        color: 'red',
        boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.error.main,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
        },
      },
    },
  },
  helperText: {
    fontSize: '14px',
    color: '#6b737b',
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
  errorWrapper: {
    paddingTop: theme.spacing(0.6),
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(100%)',
  },
  panelRoot: {
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    marginBottom: theme.spacing(2),
  },
  panelExpanded: {
    backgroundColor: '#fff !important',
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  panelDisabled: {
    backgroundColor: '#fff !important',
  },
  panelSummary: {
    minHeight: 'auto !important',
  },
  panelSummaryContent: {
    margin: '0 !important',
  },
  panelDetails: {
    padding: '0px',
  },
  heading: {
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobForm = () => {
  return <div id="grnhse_app"></div>;
};

function Application(props) {
  const { title, jobId, offices, departments } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const head = [];
  head.pageScripts = [
    {
      src: `https://boards.greenhouse.io/embed/job_board/js?for=${process.env.GREENHOUSE_BOARD_TOKEN}`,
      type: 'text/javascript',
      async: true,
    },
    {
      src: '/application-form.js',
      type: 'text/javascript',
      defer: true,
    },
  ];
  head.pageMeta = [
    {
      id: 'gh_id',
      name: 'gh:id',
      content: jobId,
    },
  ];
  const meta = [...head.pageMeta];
  const scripts = [...head.pageScripts];
  return (
    <React.Fragment>
      <Box>
        <Box mb={2}>
          <Typography
            component="p"
            variant="h4"
            color="secondary.main"
            sx={{ fontWeight: 700 }}
          >
            LOCATION{offices.length > 1 ? 'S' : ''}
          </Typography>
          <Box display="flex" flexDirection="column" mt={0.5}>
            {offices.map((item, key, arr) => {
              return (
                <Box key={key}>
                  <Typography
                    component="p"
                    variant="body1"
                    sx={{ fontWeight: 400 }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box mb={4}>
          <Typography
            component="p"
            variant="h4"
            color="secondary.main"
            sx={{ fontWeight: 700 }}
          >
            DEPARTMENT
          </Typography>
          {departments && departments.length > 0 && (
            <Box display="flex" mt={0.5}>
              {departments.map((item, key) => {
                return (
                  <Box key={key}>
                    <Typography
                      component="p"
                      variant="body1"
                      sx={{ fontWeight: 400 }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
        <Box
          component="button"
          onClick={handleClickOpen}
          sx={{ background: 'none', border: 0, padding: 0 }}
        >
          <BtnLight data="Apply now" aria-label="Apply Now" />
        </Box>
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Helmet script={scripts} meta={meta}></Helmet>
        <Box className={classes.content} px={[0, 0, 0, 16]}>
          <Container maxWidth="lg">
            <Box display="flex" justifyContent="flex-end" py={2}>
              <IconButton
                edge="start"
                color="secondary"
                onClick={handleClose}
                aria-label="close"
                className={classes.btn}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>APPLY</Box>
            <Box pt={3} pb={6}>
              <Typography
                component="h1"
                variant="h3"
                color="text.white"
                align="center"
              >
                {title}
              </Typography>
            </Box>
            <JobForm />
          </Container>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

Application.propTypes = {
  title: PropTypes.string.isRequired,
  jobId: PropTypes.number.isRequired,
  offices: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
};

export default Application;
