import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import getJobPostPathName from 'helpers/getJobPostPathName';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function JobCard(props) {
  const { data, last } = props;
  const job = data;
  return (
    <Box
      component={Link}
      to={`/${getJobPostPathName(job)}`}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        boxShadow: '0px 4px 4px rgba(205, 205, 205, 0.2)',
        borderRadius: '7px',
        textDecoration: 'none',
        color: 'inherit',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0px 4px 4px rgba(205, 205, 205, 0.8)',
        },
      }}
      bgcolor="background.default"
      py={3}
      px={[2, 4, 8]}
      mb={last ? 0 : 2}
    >
      <Box>
        {job.title && (
          <Box mb={1}>
            <Typography component="p" variant="h5">
              {job.title}
            </Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {job.offices && job.offices.length > 0 && (
            <Box display="flex">
              {job.offices.map((item, key, arr) => {
                var marginRight = arr.length - 1 === key ? 0 : 1;
                var ItemName =
                  arr.length - 1 === key ? item.name : item.name + ',';
                return (
                  <Box key={key} mr={marginRight}>
                    <Typography component="p" variant="subtitle3">
                      {ItemName}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          {job.departments && job.departments[0] && job.departments[0].name && (
            <React.Fragment>
              <Box
                mx={1}
                sx={{
                  height: '12px',
                  width: '2px',
                  backgroundColor: 'text.lblue',
                }}
              />
              <Box>
                <Typography component="p" variant="subtitle3">
                  {job.departments[0].name}
                </Typography>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
      <Box>
        <ArrowForwardIosIcon sx={{ color: 'text.lblue' }} />
      </Box>
    </Box>
  );
}

JobCard.propTypes = {
  data: PropTypes.object.isRequired,
  last: PropTypes.bool.isRequired,
};

export default JobCard;
