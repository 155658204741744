import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RightArrowWhite from 'images/right-arrow.svg';

function BtnDefault(props) {
  const { labelClass, data } = props;
  return (
    <Box
      component="span"
      className={labelClass ? labelClass : ''}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      px={5}
      py={1}
      sx={{
        width: ['100%', '100%', 'auto'],
        backgroundColor: 'transparent',
        transition:
          'background-color 0.3s ease-in-out, border-color 0.3s ease-in-out',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: '2px',
        color: '#fff !important',
        border: '3px solid',
        borderColor: 'secondary.main',
        borderRadius: '50px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'background.hover',
        },
        '&:focus': {
          backgroundColor: 'background.focus',
          borderColor: 'background.focus',
        },
      }}
    >
      <Typography component="span" variant="button">
        {data}
      </Typography>
      <Box component="span" ml={2} display="flex">
        <RightArrowWhite />
      </Box>
    </Box>
  );
}

BtnDefault.propTypes = {
  labelClass: PropTypes.string,
  data: PropTypes.node.isRequired,
};

export default BtnDefault;
