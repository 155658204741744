import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { Context } from 'store/provider';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import linkResolver from 'helpers/linkResolver';
import { Typography } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import BREAKPOINTS from 'constants/breakpoints';

function MobileMenu() {
  const [state, dispatch] = useContext(Context);
  const onlyWidth = useWindowWidth();
  const toggleMenu = () => {
    dispatch({ type: 'SET_MENU', payload: !state.menu });
  };

  useEffect(() => {
    if (onlyWidth >= BREAKPOINTS.DESKTOP) {
      dispatch({ type: 'SET_MENU', payload: false });
    }
  }, [onlyWidth]);

  const staticData = useStaticQuery(graphql`
    query MobileMainMenuQuery {
      prismicGlobalSetup {
        _previewable
        data {
          main_navigation {
            link {
              uid
              type
              id
              document {
                ... on PrismicPage {
                  data {
                    is_homepage
                    page_title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { data, isPreview } = useMergePrismicPreviewData(staticData);
  return (
    <Box
      sx={{
        height: '50vh',
        width: '100%',
        backgroundColor: 'rgba(11, 27, 46, 0.95);',
        transition: 'transform 0.3s ease-in-out',
        transform: `translateY(${state.menu ? '0%' : '-100%'})`,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 1,
      }}
    >
      {data.prismicGlobalSetup.data.main_navigation && (
        <ContainerDefault>
          <Box
            component="ul"
            display="flex"
            p={0}
            flexDirection="column"
            m={0}
            mt={8}
          >
            {data.prismicGlobalSetup.data.main_navigation.map((item, key) => {
              return (
                <Box
                  component="li"
                  flexDirection="column"
                  alignItems="center"
                  sx={{
                    listStyle: 'none',
                  }}
                  key={key}
                >
                  <Box
                    component={Link}
                    onClick={() => toggleMenu()}
                    py={1.5}
                    sx={{
                      display: 'block',
                      width: '100%',
                      color: 'text.white',
                      textDecoration: 'none',
                      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                      borderLeftWidth: '4px',
                      borderLeftStyle: 'solid',
                      borderLeftColor: 'transparent',
                      transition: 'border-left-color 0.3s ease-in-out',
                      paddingLeft: '25px',
                      '&:hover': {
                        borderLeftColor: 'text.lblue',
                      },
                      '&.active': {
                        borderLeftColor: 'text.lblue',
                      },
                    }}
                    to={
                      item.link && item.link.type == 'page'
                        ? item.link.document.data.is_homepage
                          ? '/'
                          : linkResolver(item.link)
                        : ''
                    }
                    activeClassName="active"
                  >
                    <Typography component="span" variant="h6">
                      {item.link.document.data.is_homepage
                        ? 'About us'
                        : item.link.document.data.page_title.text}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </ContainerDefault>
      )}
    </Box>
  );
}

export default MobileMenu;
