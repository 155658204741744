import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { DropDownSelect } from 'components/ui';

function SearchFilters(props) {
  const { offices, departments } = props;
  const employment_type = [
    { node: { gh_Id: 'permanent', name: 'Full Time' } },
    { node: { gh_Id: 'part_time', name: 'Part Time' } },
  ];
  const showTypeDropdown = false;
  return (
    <Grid container spacing={{ xs: 3, md: 8 }}>
      <Grid item xs={12} md={6} lg={4}>
        <DropDownSelect
          keyset="FILTER_LOCATION"
          title="Location"
          allValues="All Locations"
          options={offices}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DropDownSelect
          keyset="FILTER_DEPARTMENT"
          title="Teams"
          allValues="All Teams"
          options={departments}
        />
      </Grid>
      {showTypeDropdown && (
        <Grid item xs={12} md={6} lg={4}>
          <DropDownSelect
            keyset="FILTER_TYPE"
            title="Type"
            allValues="All Types"
            options={employment_type}
          />
        </Grid>
      )}
    </Grid>
  );
}

SearchFilters.propTypes = {
  offices: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
};

export default SearchFilters;
