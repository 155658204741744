import * as React from 'react';
var PrismicDOM = require('prismic-dom');
import { Elements } from 'prismic-richtext';
import linkResolver from 'helpers/linkResolver';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { BtnDefault, BtnLight } from 'components/ui';

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
const htmlSerializer = function(type, element, content, children, key) {
  switch (type) {
    case Elements.heading1:
      return (
        <Typography variant="h1" component="h1" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading2:
      return (
        <Typography variant="h2" component="h2" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading3:
      return (
        <Typography variant="h3" component="h3" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading4:
      return (
        <Typography variant="h4" component="h4" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading5:
      return (
        <Typography variant="h5" component="h5" key={key}>
          {children}
        </Typography>
      );
    case Elements.heading6:
      return (
        <Typography variant="h6" component="h6" key={key}>
          {children}
        </Typography>
      );
    case Elements.paragraph:
      return (
        <Typography variant="body1" component="p" key={key}>
          {children}
        </Typography>
      );
    case Elements.preformatted:
      return <pre key={key}>{children}</pre>;
    case Elements.strong:
      return <strong key={key}>{children}</strong>;
    case Elements.em:
      return <em key={key}>{children}</em>;
    case Elements.listItem:
      return <li key={key}>{children}</li>;
    case Elements.oListItem:
      return <li key={key}>{children}</li>;
    case Elements.list:
      return (
        <ul key={key} className="text-align-left richtext-list">
          {children}
        </ul>
      );
    case Elements.oList:
      return <ol key={key}>{children}</ol>;
    case Elements.image:
      var linkUrl = element.linkTo
        ? PrismicDOM.Link.url(element.linkTo, linkResolver)
        : null;
      var linkTarget =
        element.linkTo && element.linkTo.target ? element.linkTo.target : '';
      var wrapperClassList = [element.label || '', 'block-img'];
      var img = (
        <img
          src={element.url}
          alt={element.alt || ''}
          copyright={element.copyright || ''}
        />
      );
      return (
        <div
          key={key}
          target={linkTarget}
          className={wrapperClassList.join(' ')}
        >
          {linkUrl ? (
            <a className="body-text-link" href={linkUrl}>
              {img}
            </a>
          ) : (
            img
          )}
        </div>
      );
    case Elements.embed:
      return (
        <div
          key={key}
          data-oembed={element.oembed.embed_url}
          data-oembed-type={element.oembed.type}
          data-oembed-provider={element.oembed.provider_name}
        >
          {element.oembed.html}
        </div>
      );
    case Elements.hyperlink:
      var target = element.data.target
        ? 'target="' + element.data.target + '" rel="noopener"'
        : '';
      linkUrl = PrismicDOM.Link.url(element.data, linkResolver);
      return (
        <a key={key} className="body-text-link hyperlink" href={linkUrl}>
          {children}
        </a>
      );
    case Elements.label:
      var textColor = 'text.primary';
      if (element.data.label == 'text-color-white') {
        textColor = 'text.white';
      }
      if (element.data.label == 'text-color-lblue') {
        textColor = 'text.lblue';
      }
      if (element.data.label == 'text-color-dblue') {
        textColor = 'text.dblue';
      }
      if (
        element.data.label == 'text-color-lgrey' ||
        element.data.label == 'subtitle1-text-color-lgrey'
      ) {
        textColor = 'text.lgrey';
      }
      if (element.data.label == 'rounded-btn-default') {
        return (
          <React.Fragment key={key}>
            <BtnDefault
              labelClass={element.data.label ? element.data.label : ''}
              data={children}
            />
          </React.Fragment>
        );
      }
      if (element.data.label == 'rounded-btn-light') {
        return (
          <React.Fragment key={key}>
            <BtnLight
              labelClass={element.data.label ? element.data.label : ''}
              data={children}
            />
          </React.Fragment>
        );
      }
      if (element.data.label == 'subtitle1-text-color-lgrey') {
        return (
          <Box
            key={key}
            component="span"
            sx={{
              color: textColor,
            }}
            className={element.data.label ? element.data.label : ''}
          >
            <Typography component="span" variant="subtitle1">
              {children}
            </Typography>
          </Box>
        );
      }
      if (element.data.label == 'thin-text') {
        return (
          <Typography component="span" variant="body2" key={key}>
            {children}
          </Typography>
        );
      }
      if (element.data.label == 'subtitle-color-dblue') {
        return (
          <Typography component="span" variant="subtitle4" key={key}>
            <Box component="span" sx={{ color: 'text.dblue' }}>
              {children}
            </Box>
          </Typography>
        );
      }
      return (
        <Box
          key={key}
          component="span"
          sx={{
            color: textColor,
          }}
          className={element.data.label ? element.data.label : ''}
        >
          {children}
        </Box>
      );
    case Elements.span:
      if (content) {
        return content.split('\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = React.createElement(
              'br',
              propsWithUniqueKey({}, brIndex)
            );
            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }
    default:
      return null;
  }
};

export default htmlSerializer;
